<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
        sm="12"
      >
        <b-button
          v-if="showInstallButton"
          type="submit"
          variant="primary"
          block
          @click="installPWA"
        >
          Install
        </b-button>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    showInstallButton() {
      return this.$store.state.isInstallPromptShown
    },
    deferredPrompt() {
      return this.$store.state.deferredPrompt
    },
  },
  created() {
    this.data = {
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: '230k',
          subtitle: 'Sales',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: '8.549k',
          subtitle: 'Customers',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '1.423k',
          subtitle: 'Products',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '$9745',
          subtitle: 'Revenue',
          customClass: '',
        },
      ],
    }
  },
  destroyed() {
    // window.removeEventListener('beforeinstallprompt', this.installPWA)
  },
  methods: {
    async installPWA() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt()
        // Handle user response, then...
        this.$store.commit('setShowInstallPrompt', false)
      }
    },
  },
}
</script>

<style>

</style>
